import React, { useState } from 'react';
import { ModalR, MultiSelect, Spinner } from '../../../common';
import cls from './SelectAndMultiselect.module.scss';
import { SelectR } from '../../../common/UI/Select/Select';
import MessageContainer from '../MessageContainer/MessageContainer';
import { Card } from '../../BlueprintsComps/ProcessesTab/Card';
import classNames from 'classnames';

const R = require('ramda');

const SelectAndMultiselect = props => {
	const { selectionType = 'Dropdown', items, fieldIcon, placeholder, fieldLabel, onChange, visible = true, mandatory = false, error, loading, onFocus, onClicked = () => { }, value, className, onClose = () => { }, onOpen = () => { }, titleProps = {}, children } = props;
	const [show, setShow] = useState(true);
	return (
		<div onFocus={onFocus} onClick={onOpen} className={cls.selectAndMultiselectHost} style={{ display: visible ? '' : 'none' }}>
			{fieldLabel ? (
				<label className={cls.fieldLabel} style={{ ...titleProps }}>
					{fieldLabel} {mandatory && <span>*</span>}
				</label>
			) : (
				<></>
			)}
			<div className={cls.tiles}>
				{selectionType === 'Tiles' &&
					items.map((item, index) => (
						<Card
							cardTitle={item.label}
							key={index}
							onClick={() => {
								onChange(`${item.value}`);
							}}
						/>
					))}
			</div>

			{selectionType === 'multiple' && (
				<MultiSelect
					loading={loading}
					items={items ?? []}
					toggleLeftIcon={fieldIcon}
					notags
					error={!!error}
					initialSelectedValues={value && R.split(',', value)}
					itemType={'checkboxes'}
					tmiClassName={cls.dropdownInput}
					className={className ? className : cls.multiselectComponent}
					molclassname={cls.multiselectMenuOverlayList}
					moliclassname={cls.multiselectMenuOverlayListItem}
					menuButtonPlaceholder={cls.menuButtonPlaceholder}
					placeholder={placeholder}
					checkboxEmplacement={'right'}
					onOpen={open => onClicked(open)}
					onChange={selection => {
						onChange(selection.map(e => e.label).toString());
					}}
				>
					{children}
				</MultiSelect>
			)}
			{(selectionType === 'single' || selectionType === 'Dropdown' || selectionType === 'decision' || selectionType === 'modules') && (
				<SelectR
					className={className ? className : cls.dropdownSelectComponent}
					tmiClassName={cls.dropdownInput}
					molclassname={cls.dropdownSelectMenuOverlayList}
					moliclassname={cls.dropdownSelectMenuOverlayListItem}
					menuButtonPlaceholder={cls.menuButtonPlaceholder}
					selectType={'checkbox'}
					loading={loading}
					title={''}
					error={error}
					placeholder={placeholder}
					onOpen={open => onClicked(open)}
					onChange={selection => {
						onChange(`${selection.value}`);
					}}
					items={items ?? []}
					defaultSelected={value && items.find(el => el.label === value)}
				>
					{children}
				</SelectR>
			)}
			{loading && show ? (
				<ModalR blured={true} scrollable={true} isOpen={loading && show} shadowedContainerStyle={{ width: '200px' }}>
					<div className={cls.center} style={{ width: '200px', height: '50px' }} onClick={() => setShow(false)}>
						<Spinner type={'BallTriangle'} color="black" height={30} width={`30px`} style={{ marginRight: '30px' }} />
						{'Processing...'}
					</div>
				</ModalR>
			) : null}
			{error && <MessageContainer message={error} />}
		</div>
	);
};
export default SelectAndMultiselect;

export const SelectAndMultiselect_Tiles = props => {
	const { selectionType, items, fieldIcon, placeholder, fieldLabel, onChange, visible, mandatory, error, loading, onFocus, value } = props;

	return (
		<div onFocus={onFocus} className={cls.selectAndMultiselectHost} style={{ display: visible ? '' : 'none' }}>
			{fieldLabel ? (
				<label className={cls.fieldLabel}>
					{fieldLabel} {mandatory && <span>*</span>}
				</label>
			) : (
				<></>
			)}
			{selectionType === 'multiple' && (
				<MultiSelect
					loading={loading}
					items={items ?? []}
					toggleLeftIcon={fieldIcon}
					notags
					error={!!error}
					initialSelectedValues={value && R.split(',', value)}
					itemType={'checkboxes'}
					tmiClassName={cls.dropdownInput}
					className={cls.multiselectComponent}
					molclassname={cls.multiselectMenuOverlayList}
					moliclassname={cls.multiselectMenuOverlayListItem}
					menuButtonPlaceholder={cls.menuButtonPlaceholder}
					placeholder={placeholder}
					checkboxEmplacement={'right'}
					onChange={selection => {
						onChange(selection.map(e => e.label).toString());
					}}
				/>
			)}
			{selectionType === 'single' && (
				<SelectR
					className={cls.dropdownSelectComponent}
					tmiClassName={cls.dropdownInput}
					molclassname={cls.dropdownSelectMenuOverlayList}
					moliclassname={cls.dropdownSelectMenuOverlayListItem}
					menuButtonPlaceholder={cls.menuButtonPlaceholder}
					selectType={'radio'}
					loading={loading}
					toggleLeftIcon={fieldIcon}
					title={''}
					error={!!error}
					placeholder={placeholder}
					onChange={selection => {
						onChange(`${selection.value}`);
					}}
					items={items ?? []}
					defaultSelected={value && items.find(el => el.label === value)}
				/>
			)}
			{error && <MessageContainer message={error} />}
		</div>
	);
};
