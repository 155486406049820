import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isSafari } from 'react-device-detect';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Element } from 'react-scroll';
import { attention, DeleteBin, FilesAndFolders, successDone } from '../../assets/images/icons';
import roundArrow from './CompanyOrganizationalChart/roundArrow.svg';
import { TheRoleInformationContext, useRoleInformationContext } from '../../context/appRender.context';
import { setDuplicatePositionInfo, setPositionInfo } from '../../reduxStore/actions/flowActions';
import { actions_InfoModalOpen, setOnBoardingModalsVisibility } from '../../reduxStore/actions/modalsActions';
import {
	addingUserToPosition,
	deletePosition_AndItsChildren,
	deletePosition_AndKeepChildren,
	duplicateSpecificPosition,
	getCompanyDepartmentManagerialPositions,
	getCompanyDepartments,
	removeUserFromPosition,
	sharingUserToPosition,
	unsharingUserFromPosition,
	updatePosition,
} from '../../services/company.service';
import { Button, CustomCheckbox, FieldWrapper, ModalR, RenderDynamicTextInput, RenderPositionsPeople, RenderSharedResourcesForPosition, RenderYesNoOptions, SelectD } from '../common';
import cls from './RoleInformation.module.scss';

const RoleInformation = props => {
	const fieldWrapProps = { useContext: useRoleInformationContext };
	const navigate = useNavigate();
	const location = useLocation();
	const { positionInfo, onSetPositionInfo, companyDepartments, companyUsersArray, onBoardingVisible, isOnboarding } = props;
	const [isManagerialPosition, setIsManagerialPosition] = useState(+positionInfo?.is_managerial == 1 ? true : false);

	const [usersToAddOnPosition, setUsersToAddOnPosition] = useState([]);
	const [usersToShareOnPosition, setUsersToShareOnPosition] = useState([]);

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showDeleteResult, setShowDeleteResult] = useState({ visible: false, message: '' });
	const [showDuplicateModal, setShowDuplicateModal] = useState(false);

	const [isGMDirectDep, setIsGMDirectDep] = useState(false);
	const mainContainerRef = useRef();
	const printingAreaRef = useRef();
	const onCloseRoleInformationAction = () => {
		const backTo = location?.state?.backPath;
		if (backTo?.length > 0) {
			navigate(backTo);
		} else {
			onBoardingVisible && isOnboarding ? navigate('/chart') : navigate('/chart');
		}
	};

	useEffect(() => {
		retreiveDepartmentsFromServer();
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
		window.scrollTo(0, 0);
	}, []);
	const retreiveDepartmentsFromServer = () => {
		getCompanyDepartments().subscribe({
			next: data => {
				console.log('getCompanyDepartments, [data]', data?.length);
			},
			error: error => {
				console.log('getCompanyDepartments, [error]', error);
			},
		});
	};
	useEffect(() => {
		if (location?.state?.isGMDirectDep == true) {
			setIsGMDirectDep(true);
		}
	}, [location]);

	const deleteThisPosition = () => setShowDeleteModal(true);
	const onSavePosition = useCallback(() => {
		updatePosition(positionInfo.id, positionInfo).subscribe({
			next: data => {
				console.log('updatePosition, [data]', data);
				onCloseRoleInformationAction();
			},
			error: error => {
				console.log('updatePosition, [error]', error);
			},
			complete: () => {
				console.log('updatePosition delivered sucesfully');
			},
		});
	}, [positionInfo]);
	const onBackButtonPress = () => onCloseRoleInformationAction();
	const DuplicatePositionComponent = props => {
		const { isOpen, onClose } = props;
		const [loading, setLoading] = useState(false);
		const [showConfirm, setShowConfirm] = useState(false);
		const [resultMessage, setResultMessage] = useState('');
		const [selectedParentPosition, setSelectedParentPosition] = useState({ label: '', value: '' });
		const [parentPositions, setParentPositions] = useState([]);
		const [positionNewName, setPositionNewName] = useState('');
		useEffect(() => {
			retrieveParentPositions(positionInfo.department_id);
		}, [isOpen]);
		const retrieveParentPositions = department_id => {
			getCompanyDepartmentManagerialPositions(department_id).subscribe({
				next: data => {
					console.log('getCompanyDepartmentManagerialPositions, [data]', data);
					const parentPos = data?.map(i => ({ label: i.name, value: i.id, icon: <img src={roundArrow} alt=">" style={{ width: '20px', height: '20px' }} /> }));
					setParentPositions([...parentPos]);
				},
				error: error => {
					console.log('getCompanyDepartmentManagerialPositions, [error]', error);
				},
			});
		};
		const onDuplicatePressed = useCallback(() => {
			setLoading(true);
			if (positionInfo?.id?.length > 0) {
				duplicateSpecificPosition(positionInfo.id).subscribe({
					next: data => {
						console.log('duplicateSpecificPosition, [data]', data);
						setLoading(false);
						setShowConfirm(true);
						setResultMessage('Position has been succesfully duplicated.');
					},
					error: error => {
						console.log('duplicateSpecificPosition, [error]', error);
						setLoading(false);
						setShowConfirm(true);
						setResultMessage('Error occured in duplicating position.');
					},
				});
			}
		}, [positionInfo]);
		const onBackToPositionPressed = useCallback(() => {
			setLoading(false);
			setShowConfirm(false);
			onClose();
		}, [positionInfo]);
		return (
			<ModalR
				shadowedContainerStyle={{ maxWidth: '700px', minWidth: '475px', width: '60%' }}
				onClose={() => {
					setShowConfirm(false);
					onClose();
				}}
				isOpen={isOpen}
				backdropColor={true}
				blured={true}
				hasCloseButton
			>
				<div className={cls.duplicateModalContainer}>
					{!showConfirm ? (
						<div className={cls.duplicatingPositionContainer}>
							<h1 className={cls.duplicatingPosition_title}>{'Duplicating Position'}</h1>
							{!loading && (
								<div className={cls.duplicatingPosition_description}>
									<p className={cls.duplicatingPosition_fieldLabel}> {'Parent Position'} </p>
									<div className={cls.duplicatingPosition_parent}>
										<SelectD
											key={'Position'}
											isClearable
											items={parentPositions}
											defaultSelected={selectedParentPosition?.label ?? ''}
											placeholder="Select Parent Position"
											onChange={selection => {
												selection?.value && setSelectedParentPosition(selection);
											}}
											moliclassname={cls.duplicatingPosition_selectPositionmoli}
										/>
									</div>
									<p className={cls.duplicatingPosition_fieldLabel}>{'Name of the New Position'}</p>
									<div className={cls.duplicatingPosition_newName}>
										<RenderDynamicTextInput numberOfLines={1} plcHoffset={'9px'} maxLength={48} placeholder="Enter new position name ..." text={positionNewName} onTextChanged={text => setPositionNewName(text)} />
									</div>
									<p className={cls.duplicatingPosition_annotation}>{'You want to duplicate to the first high hierarchical position?'}</p>
								</div>
							)}
							{!loading && (
								<div>
									<Button icon={<FilesAndFolders style={{ padding: '1px 1px', width: '24px', height: '24px' }} />} title="Complete" style={{ marginLeft: '0px', width: '100%' }} titleStyle={{ textAlign: 'center', padding: '0 2px 0 0' }} clicked={onDuplicatePressed} />
								</div>
							)}
						</div>
					) : (
						<div className={cls.duplicatingPosition_resultOperationContainer}>
							<img src={successDone} alt="" style={{ padding: '10px 10px', width: '194px', height: '194px' }} />
							<div className={cls.duplicatingPosition_resultContainer}>
								<h1 className={cls.duplicatingPosition_resultText}>{resultMessage}</h1>
							</div>
							<div className={cls.buttonContainer}>
								<Button title="Back to Position" style={{ marginLeft: '0px' /* width: '80%' */ }} titleStyle={{ textAlign: 'center', padding: '0 10px 0 0' }} clicked={onBackToPositionPressed} />
							</div>
						</div>
					)}
					{loading && (
						<div className={cls.loadingContainer}>
							<Loader type={isSafari ? 'Watch' : 'Grid'} color="var(--app-color-gray-dark)" /*  visible={props.loading} */ secondaryColor="blue" height={'250px'} width={'250px'} /*radius={50} height={100} */ />
						</div>
					)}
				</div>
			</ModalR>
		);
	};
	const DeletePositionComponent = props => {
		const { isOpen, onClose } = props;
		const [checkedFirst, setCheckedFirst] = useState(false);
		const [checkedSecond, setCheckedSecond] = useState(false);
		const [loading, setLoading] = useState(false);
		const [showCase, setShowCase] = useState(0);
		const [deleteMessage] = useState('');
		const [warningAnnotation, setWarningAnnotation] = useState(false);
		const settingWarningAnnotation = val => {
			setWarningAnnotation(val);
		};
		useEffect(() => {
			if (checkedFirst && checkedSecond) {
				choseFirstOption();
			}
		}, [checkedFirst]);
		useEffect(() => {
			if (checkedSecond && checkedFirst) {
				choseSecondOption();
			}
		}, [checkedSecond]);
		const choseFirstOption = useCallback(() => {
			if (checkedFirst && checkedSecond) {
				setCheckedSecond(false);
			}
		}, [checkedFirst, checkedSecond]);
		const choseSecondOption = useCallback(() => {
			if (checkedSecond & checkedFirst) {
				setCheckedFirst(false);
			}
		}, [checkedFirst, checkedSecond]);
		useEffect(() => {
			if (showCase == 1) {
				var wa = `You are about to delete ${positionInfo.name}`;
				const sps = positionInfo.children.length;
				if (sps > 0) {
					if (checkedFirst) wa += ` and ${sps > 1 ? 'all ' + sps + ' subpositions' : sps + ' subposition '} associated with this position.`;
					if (checkedSecond) wa += `. All ${sps} subpositions associated within will will be assigned to the first higher hierarchical position.`;
				} else {
					wa += '.';
				}
				settingWarningAnnotation(wa);
			}
		}, [showCase]);

		const onBackToPositionPressed = () => {
			setLoading(false);
			setShowCase(0);
			onClose({ succes: undefined, message: 'User canceled.' });
		};
		const onDeletePressed = () => {
			if (!checkedFirst && !checkedSecond) {
				setCheckedSecond(true);
			} else {
				setShowCase(1);
			}
		};
		const onConfirmDeletePressed = useCallback(
			alsoChildren => {
				setLoading(true);
				if (alsoChildren) {
					deletePosition_AndItsChildren(positionInfo.id).subscribe({
						next: data => {
							console.log('deletePosition_AndItsChildren, [data]', data);
							onClose({ success: true, message: 'The position has been succesfully deleted.' });
						},
						error: error => {
							console.log('deletePosition_AndItsChildren, [error]', error);
							onClose({ success: false, message: 'Error occured in deleting position.' });
						},
					});
				} else {
					deletePosition_AndKeepChildren(positionInfo.id).subscribe({
						next: data => {
							console.log('deletePosition_AndItsChildren, [data]', data);
							onClose({ success: true, message: 'The position has been succesfully deleted.' });
						},
						error: error => {
							console.log('deletePosition_AndKeepChildren, [error]', error);
							onClose({ success: false, message: 'Error occured in deleting position.' });
						},
					});
				}
			},
			[positionInfo]
		);
		return (
			<ModalR
				shadowedContainerStyle={{ maxWidth: '700px', minWidth: '475px', width: '70%' }}
				onClose={() => {
					onClose({ succes: undefined, message: 'User canceled.' });
				}}
				isOpen={isOpen}
				backdropColor={true}
				blured={true}
				hasCloseButton={[1].indexOf(showCase) ? true : false}
			>
				<div className={cls.deleteModalContainerx}>
					{showCase == 0 && (
						<div className={cls.deletePositionContainer}>
							<h1 className={cls.deletePosition_title}>{'Delete Position'}</h1>
							<p className={cls.deletePosition_description}>{'Please select the following action:'}</p>
							<div className={cls.deletePosition_checkboxContainer}>
								<CustomCheckbox
									label={'I want to delete the position and all it’s subpositions. '}
									tooltipText={'Whatewer can pe explained in here'}
									onChange={checked => {
										setCheckedFirst(checked);
									}}
									defaultChecked={checkedFirst}
									mainContainerStyle={{ margin: '0 0 0 0', padding: 0 }}
									checkMarkStyle={{ width: '24px', height: '24px' }}
								/>
							</div>
							<div className={cls.deletePosition_checkboxContainer}>
								<CustomCheckbox
									label={'I want to just delete the position.'}
									tooltipText={'Whatewer can pe explained in here'}
									onChange={checked => {
										setCheckedSecond(checked);
									}}
									defaultChecked={checkedSecond}
									mainContainerStyle={{ margin: '0 0 0 0', padding: 0 }}
									checkMarkStyle={{ width: '24px', height: '24px' }}
								/>
							</div>
							<div className={cls.deletePosition_buttonContainer}>
								<Button icon={<DeleteBin />} title="DELETE" style={{ marginLeft: '0px', width: '79%', padding: '0 10px' }} titleStyle={{ textAlign: 'center', padding: '0 10px 0 0' }} clicked={() => onDeletePressed()} />
							</div>
						</div>
					)}
					{showCase == 1 && (
						<div className={cls.warningDeletionContainer}>
							<h1 className={cls.warningDeletion_warning}>{'Warning'}</h1>
							<p className={cls.warningDeletion_warningAnnotation}>{'This action is irreversible.'}</p>
							<div className={cls.warningDeletion_warningInformation}>
								<p>{warningAnnotation}</p>
							</div>
							<div className={cls.warningDeletion_buttonContainer}>
								<Button loading={loading} title="Permanently Delete" style={{ marginLeft: '0px' /* width: '80%' */ }} titleStyle={{ textAlign: 'center', padding: '0 10px 0 0' }} clicked={() => onConfirmDeletePressed(checkedFirst && !checkedSecond)} />
							</div>
						</div>
					)}
					{showCase == 2 && (
						<div className={cls.loadingContainer}>
							<Loader type={isSafari ? 'Watch' : 'Grid'} color="var(--app-color-gray-dark)" /*  visible={props.loading} */ secondaryColor="blue" height={'250px'} width={'250px'} /*radius={50} height={100} */ />
						</div>
					)}
					{showCase == 3 && (
						<div className={cls.resultOperationWrapper}>
							<div className={cls.resultOperationContainer}>
								<div className={cls.resultContainer}>
									<h1 className={cls.resultText}>{deleteMessage}</h1>
								</div>
								<div className={cls.buttonContainer}>
									<Button title="Back to Chart" style={{ marginLeft: '0px' /* width: '80%' */ }} titleStyle={{ textAlign: 'center', padding: '0 10px 0 0' }} clicked={() => onBackToPositionPressed()} />
								</div>
							</div>
						</div>
					)}
				</div>
			</ModalR>
		);
	};
	const DeletePositionResult = props => {
		const { isOpen, onClose, message } = props;
		const [deleteMessage] = useState(message);
		return (
			<ModalR backdropStyle={{ height: '100%' }} shadowedContainerStyle={{ maxWidth: '700px', minWidth: '475px' }} marginTop={'2%'} onClose={onClose} hasCloseButton position={'fixed'} scrollable={true} backdropColor={true} blured={true} width={'60%'} isOpen={isOpen}>
				<div className={cls.duplicateModalContainer}>
					<div className={cls.resultOperationWrapper}>
						<div className={cls.resultOperationContainer}>
							<div className={cls.resultContainer}>
								<h1 className={cls.resultText}>{deleteMessage}</h1>
							</div>
							<div className={cls.buttonContainer}>
								<Button title="Back to Chart" style={{ marginLeft: '0px' /* width: '80%' */ }} titleStyle={{ textAlign: 'center', padding: '0 10px 0 0' }} clicked={() => onClose()} />
							</div>
						</div>
					</div>
				</div>
			</ModalR>
		);
	};
	const settingUsersToAddOnPosition = val => setUsersToAddOnPosition(val);
	useEffect(() => {
		const usersOnPos = positionInfo?.users.map(user => user.id);
		const newUsersToAdd = companyUsersArray?.filter(i => usersOnPos.indexOf(i.id) === -1);
		settingUsersToAddOnPosition(newUsersToAdd);
	}, [companyUsersArray, positionInfo]);
	const AddSingleUserToPosition = useCallback(
		singleUser => {
			if (positionInfo?.id?.length > 0 && singleUser?.value?.length > 0) {
				if (positionInfo?.shared_users?.filter(i => i.id == singleUser?.value)?.length > 0) {
					unsharingUserFromPosition(positionInfo?.id, singleUser?.value).subscribe({
						next: data => {
							console.log('unsharingUserFromPosition, [data]', data);
							addingUserToPosition(positionInfo?.id, singleUser?.value).subscribe({
								next: data => {
									console.log('addingUserToPosition, [data]', data);
								},
								error: error => {
									console.log('addingUserToPosition, [error]', error);
								},
								complete: () => {
									console.log('addingUserToPosition delivered sucesfully');
								},
							});
							return;
						},
						error: error => {
							console.log('unsharingUserFromPosition, [error]', error);
						},
					});
				} else {
					addingUserToPosition(positionInfo?.id, singleUser?.value).subscribe({
						next: data => {
							console.log('addingUserToPosition, [data]', data);
						},
						error: error => {
							console.log('addingUserToPosition, [error]', error);
						},
					});
				}
			}
		},
		[positionInfo]
	);

	const onAddUserOnPosition = useCallback(
		selectedToAdd => {
			console.log('onAddUserOnPosition: ', selectedToAdd, positionInfo?.id?.length > 0, selectedToAdd?.value?.length > 0);
			if (selectedToAdd?.length > 0) {
				selectedToAdd?.forEach(item => AddSingleUserToPosition(item));
			}
		},
		[positionInfo]
	);
	const onRemoveUserFromPosition = useCallback(
		selectedEmployee => {
			if (positionInfo?.id?.length > 0 && selectedEmployee?.id?.length > 0) {
				removeUserFromPosition(positionInfo?.id, selectedEmployee.id).subscribe({
					next: data => {
						console.log('removeUserFromPosition, [data]', data); // setSelectedEmployee();
					},
					error: error => {
						console.log('removeUserFromPosition, [error]', error);
					},
				});
			}
		},
		[positionInfo]
	);

	const settingUsersToShareOnPosition = val => setUsersToShareOnPosition(val);
	useEffect(() => {
		const usersOnPosIds = positionInfo?.users?.map(it => it.id);
		const sharedUsrIds = positionInfo?.shared_users?.map(it => it.id);
		const newUsersToShare = companyUsersArray?.filter(i => usersOnPosIds.indexOf(i.id) == -1 && sharedUsrIds.indexOf(i.id) == -1);
		settingUsersToShareOnPosition(newUsersToShare);
	}, [companyUsersArray, positionInfo]);
	const onShareUserOnPosition = useCallback(
		selectedToShare => {
			if (positionInfo?.id?.length > 0 && selectedToShare?.value?.length > 0) {
				sharingUserToPosition(positionInfo?.id, selectedToShare?.value).subscribe({
					next: data => {
						console.log('sharingUserToPosition, [data]', data);
					},
					error: error => {
						console.log('sharingUserToPosition, [error]', error);
					},
				});
			}
		},
		[positionInfo]
	);
	const onUnshareUserFromPosition = useCallback(
		selectedEmployee => {
			if (positionInfo?.id?.length > 0 && selectedEmployee?.id?.length > 0) {
				unsharingUserFromPosition(positionInfo?.id, selectedEmployee?.id).subscribe({
					next: data => {
						console.log('unsharingUserFromPosition, [data]', data);
					},
					error: error => {
						console.log('unsharingUserFromPosition, [error]', error);
					},
				});
			}
		},
		[positionInfo]
	);
	return (
		<TheRoleInformationContext>
			<ModalR scrollable={true} backdropColor={false} blured={false} width={'60%'} isOpen={true} onClose={onBackButtonPress} hasCloseButton position={'fixed'}>
				<div ref={mainContainerRef} className={cls.roleInformationWrapper}>
					<div ref={printingAreaRef} className={cls.printableContainer}>
						<div id={'scrollableContainer'} className={cls.scrollableContainer}>
							<div id="printArea" className={cls.printArea}>
								<Element name="0"></Element>
								<p className={cls.roleInformationTitleText}>{'Role Information'}</p>
								<FieldWrapper id={'roleName'} title={'Role Name'} tooltipText={'Define name for this position'} value={positionInfo.name} {...fieldWrapProps}>
									<RenderDynamicTextInput
										numberOfLines={1}
										maxLength={32}
										placeholder={'Insert role name'}
										text={positionInfo.name}
										onTextChanged={text => {
											onSetPositionInfo({
												name: text?.[0]?.length > 0 ? text[0] : text,
											});
										}}
									/>
								</FieldWrapper>
								{!isGMDirectDep && (
									<>
										<FieldWrapper id={'parentPosition'} title={'Parent Position'} tooltipText={'This field is readOnly'} readOnly value={positionInfo.parent_position?.name ?? ' /Parent position not reachable...  ' /* positionInfo.name */} {...fieldWrapProps} />
										<FieldWrapper
											id={'department'}
											title={'Department:'}
											tooltipText={'This field is readOnly'}
											readOnly
											value={
												companyDepartments.filter(({ value }) => {
													return value == positionInfo.department_id;
												})[0]?.label ?? ''
											}
											{...fieldWrapProps}
										/>
										<FieldWrapper id={'isManagerial'} title={'Is this a Managerial Position?'} tooltipText={!isManagerialPosition ? 'This field is readOnly' : 'Here can be selected if this position can have subpositions or not'} readOnly otherContent {...fieldWrapProps}>
											<RenderYesNoOptions value={isManagerialPosition} onValueChanged={val => setIsManagerialPosition(val)} />
										</FieldWrapper>
										{isManagerialPosition && (
											<FieldWrapper id={'subPositions'} title={'How many Positions respond to this Managerial Position?'} tooltipText={'This field is readOnly'} readOnly otherContent {...fieldWrapProps}>
												{positionInfo.children?.length > 0 ? (
													<p style={{ color: ' #4a4a4a', fontFamily: 'var(--app-text-main-font)' }}>
														{positionInfo.children
															?.map(item => item.name)
															.join(', ')
															.toString()
															.slice(0, -2)}
													</p>
												) : (
													<p style={{ color: ' #4a4a4a', fontFamily: 'var(--app-text-main-font)' }}>{'No position'}</p>
												)}
											</FieldWrapper>
										)}
									</>
								)}
							</div>

							<div id={'listsArea'} className={cls.listArea}>
								<Element name="1"></Element>
								<FieldWrapper id={'peopleInThisPosition'} title={'People in this position:'} tooltipText={'Alocate people in this position'} value={''} otherContent {...fieldWrapProps}>
									<RenderPositionsPeople {...fieldWrapProps} id={'peopleInThisPosition'} onAddUserOnPosition={onAddUserOnPosition} onRemoveUserFromPosition={onRemoveUserFromPosition} usersOnPosition={positionInfo?.users} usersToAddOnPosition={usersToAddOnPosition} />
								</FieldWrapper>
								<Element name="2"></Element>
								<FieldWrapper id={'sharedresources'} title={'Shared Resources:'} tooltipText={'Alocate shared resources for this position'} value={''} otherContent {...fieldWrapProps}>
									<RenderSharedResourcesForPosition
										{...fieldWrapProps}
										id={'sharedresources'}
										sharedUsersOnPosition={positionInfo?.shared_users}
										usersToShareOnPosition={usersToShareOnPosition}
										onShareUserOnPosition={onShareUserOnPosition}
										onUnshareUserFromPosition={onUnshareUserFromPosition}
									/>
								</FieldWrapper>
								<div className={cls.optionsContainer}>
									{[{ onClick: deleteThisPosition, immageSVG: <DeleteBin />, label: 'Delete' }].map((i, idx) => (
										<button key={idx} type={'button'} className={cls.positionOptionButton} onClick={i.onClick}>
											{i.immageSVG && i.immageSVG}
											{i.imageSrc && <img src={i.imageSrc} alt="oups" />}
											<p>{i.label}</p>
										</button>
									))}
								</div>
								<Button disabled={props.continueDisabled} loading={props.loading ? props.loading : undefined} clicked={_e => onSavePosition()} style={{}} title={'SAVE'} />

								{/* <div style={{ width: '100%', paddingTop: '200px', height: '200px' }} /> */}
							</div>
						</div>
					</div>
				</div>
			</ModalR>
			{showDeleteModal && (
				<DeletePositionComponent
					isOpen={showDeleteModal}
					onClose={p => {
						setShowDeleteModal(false);
						if ([true, false].indexOf(p?.success) > -1) {
							setShowDeleteResult({
								visible: true,
								message: p.message,
							});
						}
					}}
				/>
			)}
			{showDeleteResult?.visible && (
				<DeletePositionResult
					isOpen={showDeleteResult?.visible}
					message={showDeleteResult?.message}
					onClose={() => {
						setShowDeleteResult({ visible: false, message: '' });
						onBackButtonPress();
					}}
				/>
			)}
			{showDuplicateModal && <DuplicatePositionComponent isOpen={showDuplicateModal} onClose={() => setShowDuplicateModal(false)} />}
		</TheRoleInformationContext>
	);
};
const mapStateToProps = store => {
	const { positionInfo, companyDepartments, companyUsersArray } = store.flowReducer;
	const { onBoardingVisible, isOnboarding } = store.modalsReducer;
	return { positionInfo, companyDepartments, companyUsersArray, onBoardingVisible, isOnboarding };
};
const mapDispatchToProps = dispatch => {
	return {
		onSetPositionInfo: field => dispatch(setPositionInfo(field)),
		onActions_InfoModalOpen: options => dispatch(actions_InfoModalOpen({ isOpen: true, options: options })),
		onDuplicateSpecificPosition: positionId => dispatch(duplicateSpecificPosition(positionId)),
		onToggleModalVisibility: modalVisible => dispatch(setOnBoardingModalsVisibility(modalVisible)),
		onSetDuplicatePositionInfo: dPosInfo => dispatch(setDuplicatePositionInfo(dPosInfo)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleInformation);
