import cls from './DatePickerComp.module.scss';
import React, { useRef } from 'react';
import DatePicker from 'react-datepicker';
import { Calendar } from '../../../assets/images/icons';
import UnfocusableWrapper from '../UnfocusableWrapper/UnfocusableWrapper';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
export const DatePickerComp = props => {
	const { toggleDP = () => {}, value, selectedDate, dateChangeHandler, inputContainerClassName, inputClassName, inputStyle, iconClassName, placeholder, id, hasCalendarIcon, ...rest } = props;
	const dateTimePickerRef = useRef();

	const toggleDatePicker = () => {
		if (dateTimePickerRef?.current?.state?.isOpen) {
			dateTimePickerRef?.current?.togglePicker(false);
		}
	};
	const onChangeDate = date => {
		dateChangeHandler?.(date);
	};
	return (
		<>
			<UnfocusableWrapper onClickOutside={toggleDatePicker}>
				<DatePicker
					onCalendarOpen={() => toggleDP(true)}
					onCalendarClose={() => toggleDP(false)}
					showYearDropdown
					dateFormatCalendar="MMMM"
					scrollableYearDropdown
					placeholderText="Founding Date"
					customInput={
						<div className={classNames(cls.inputContainer, inputContainerClassName)}>
							{hasCalendarIcon &&<FontAwesomeIcon icon={faCalendarDays} style={{width: '20px', height:'20px'}}/> }
							<input readOnly value={value} type="text" id={id ?? 'date-picker'} className={classNames(cls.inputField, inputClassName)} style={inputStyle} placeholder={placeholder} />
						</div>
					}
					selected={selectedDate ?? new Date()}
					onChange={onChangeDate}
					{...rest}
				/>
			</UnfocusableWrapper>
		</>
	);
};
