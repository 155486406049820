import cls from './ModuleLists.module.scss';
import { useTable } from 'react-table';
import { isMobile } from 'react-device-detect';
import { useState } from 'react';
import { Button } from '../../common';
import { page_default } from '../../../shared/utility';
import * as R from 'ramda';

export const ModuleLists = props => {
	const { columns = [], list = [], title, onListItemClick = row => { }, className = cls.tableContainer, children, isReport = false, modern = false, rowStyleSelected = {}, initialSelected, withColumns = true } = props;
	const [page, setPage] = useState(1);
	const [selected, setSelected] = useState(initialSelected);
	const columnsF = columns;

	function Table({ columns, data }) {
		const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
			columns,
			data,
		});
		return (
			<div className={title ? (isReport ? cls.tableReport : cls.tableHost) : cls.tableHostSimple} style={{ ...props.style, fontSize: '16px' }}>
				<table {...getTableProps()} size="sm">
					<thead>
						{headerGroups.map((headerGroup, index) => (
							<tr key={index} {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column, idx) => (
									<th key={idx} {...column.getHeaderProps()}>
										<div style={{ border: '0', padding: '10px' }}>{column.render('Header')}</div>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{rows.map(
							(row, index) =>
								prepareRow(row) || (
									<tr
										key={index}
										{...row.getRowProps()}
										onClick={() => {
											setSelected(index);
											onListItemClick(title ? (page == 12345 ? list[row.index] : list.slice(page * page_default - page_default, page * page_default)[row.index]) : list[row.index]);
										}}
									>
										{row.cells.map((cell, index) => {
											return (
												<td key={index} {...cell.getCellProps()}>
													<div style={{ border: '0' }}>{cell.render('Cell')}</div>
												</td>
											);
										})}
									</tr>
								)
						)}
					</tbody>
				</table>
			</div>
		);
	}

	return (
		<div className={className ?? ''} app-variant-is-mobile={isMobile ? 'true' : 'false'} style={{ userSelect: 'none', display: 'flex', flexDirection: 'column' }}>
			{children}
			{modern ? (
				<div style={{ width: 'inherit' }}>
					{title && (
						<div className={cls.actionsContainer} style={{ margin: '40px 20px 20px 20px', fontSize: '12px' }}>
							<div>{title}</div>
						</div>
					)}
					{(page == 12345 ? list : list.slice(page * page_default - page_default, page * page_default)).map((row, index) => {
						const obj ={};
						columns.map(col=> {
							obj[col] = row[col];
						})
						const hasImg = Object.values({ ...obj, label: '' })?.find(val => `${val}`?.includes('jpg') || `${val}`?.includes('jpeg') || `${val}`.includes('png'));
						return (
							<div
								style={{ ...(initialSelected == -1 && selected == -1 ? (initialSelected === index ? rowStyleSelected : {}) : selected === index ? rowStyleSelected : {}), display: 'flex', width: '100%' }}
								className={cls.filtersContainer}
								key={index}
								onClick={() => {
									setSelected(index);
									onListItemClick(title ? (page == 12345 ? list[index] : list.slice(page * page_default - page_default, page * page_default)[index]) : list[index]);
								}}
							>
								{hasImg && (
									<div >
										<img alt="lg" src={hasImg} style={{ padding: '10px 10px 10px 0', objectFit: 'contain', width: '60px', height: '60px', cursor: 'pointer' }} />
									</div>
								)}
								<div style={{ width: 'inherit', display: 'flex', flexDirection: 'column',justifyContent:'center' }}>
									{columnsF.map((col, i) => {
										if (`${row[col]}`?.includes('jpg') || `${row[col]}`?.includes('jpeg') || `${row[col]}`?.includes('png')) {
											return null;
										}

										return hasImg ? (
											<div key={i} className={cls.actionsContainer} style={{ fontSize: i == 0 ? '15px' : '10px' }}>
												<div style={{ width: '90%', textAlign: 'left', fontWeight: i == 0 ? 'bold' : '', color: i == 0 ? 'black' : '' }}> {row[col]}</div>
											</div>
										) : (
											<div style={{ width: 'inherit' }} key={i}>
												{row[col] && <div className={cls.actionsContainer}>
													{withColumns && <div style={{ width: '20%', minWidth: '100px', textAlign: 'left', fontSize: i==0 ? '12px':'10px', fontWeight: i == 0 ? '' : '', color: i == 0 ? 'var(--app-color-gray-darker)' : '' }}>{col}:</div>}
													<div style={{ width: '80%', textAlign: withColumns ? 'right' : 'left',fontSize: i==0 ? '12px':'10px', fontWeight: i == 0 ? '' : '', color: i == 0 ? 'var(--app-color-gray-darker)' : '' }}>{row[col]}</div>
												</div>}
												{i < columnsF.length - 1 && <hr style={{ borderTop: '0.5px solid #D2D2D2' }} />}
											</div>
										);
									})}
								</div>
							</div>
						);
					})}
				</div>
			) : (
				<div style={{ marginBottom: '10px' }}>
					{!title && (
						<Table
							columns={columnsF.map(x => {
								return {
									Header: () => {
										return <span>{x}</span>;
									},
									accessor: x,
								};
							})}
							data={list}
						/>
					)}

					{title && (
						<Table
							columns={[
								{
									Header: title,
									columns: columnsF.map(col => {
										return {
											Header: col,
											accessor: col,
										};
									}),
								},
							]}
							data={page == 12345 ? list : list.slice(page * page_default - page_default, page * page_default)}
						/>
					)}
				</div>
			)}
			{title && list.length > 0 && list.length > page_default + 1 && list.slice(page * page_default - page_default, page * page_default).length < page_default + 1 && (
				<div style={{ display: 'flex', width: '100%' }}>
					{!isMobile && <p style={{ padding: '10px 10px 0 0', fontSize: '12px' }}>Page:</p>}
					{[...Array(Math.ceil(isMobile ? (modern ? 4 : 8) : list.length / page_default))].map((obj, index) => (
						<Button disabled={index == page - 1 ? true : false} title={index + 1} clear key={index} style={{ width: '40px', display: index < 18 ? '' : 'none' }} clicked={() => setPage(index + 1)} titleContainer={cls.pageContainer} />
					))}
					<Button title={'All'} disabled={page == 12345 ? true : false} clicked={() => setPage(12345)} clear />
				</div>
			)}
		</div>
	);
};

export default ModuleLists;
