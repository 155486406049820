import React, { useState } from 'react';
import { componentsSettingIcon, saveInputIcon } from '../../../../../assets/images/icons';
import './FieldRow.scss';
import cls from './FieldRow.module.scss';
import MessageContainer from '../../../../SideMenuEntries/ProcessesComps/MessageContainer/MessageContainer';
import ModalR from '../../Modal/ModalR';
import Spinner from '../../Spinner/Spinner';

const FieldRow = props => {
	const { enabled = '', fieldIcon, mandatory, fieldLabel = '', visible, customIconBgColor, settingsClicked, children, hasSaveInputBtn, hasSettingsBtn, error, border, showWidth = () => {}, dragEnd = () => {}, notes, editMode = false, titleProps = {}, loading=false } = props;
	const [ show, setShow] = useState(false);
	return (
		<div
			cid={'field-row-container'}
			id={fieldLabel}
			onClick={() => showWidth(document.getElementById(fieldLabel)?.offsetWidth)}
			onDragEnd={e => {
				dragEnd({
					left: e.clientX,
					top: e.clientY,
				});
			}}
			draggable={editMode}
			style={{
				resize: editMode ? 'both' : '',
				overflow: editMode ? 'auto' : '',
				width: props.width ? props.width : '',
				minWidth: '300px',
				...props.style,
				display: visible ? '' : 'none',
				pointerEvents: enabled,
				marginBottom: '10px',
			}}
		>
			{fieldLabel ? (
				<label style={{ fontSize: '16px', ...titleProps }}>
					{fieldLabel} {mandatory && <span>*</span>}
				</label>
			) : (
				<></>
			)}
			<div className={editMode ? cls.fieldrow : 'field-row'} app-variant-has-error={error ? 'error' : ''} >
				<div className={editMode ? cls.fieldrowcontainer : 'field-row-container'}>
					<div className={editMode ? cls.fieldrowinnercontainer : 'field-row-inner-container'} style={{ backgroundColor: customIconBgColor ? customIconBgColor : '#F1F1F1', display: 'none' }}>
						<img src={fieldIcon} alt={''} />
					</div>
					{children}
				</div>

				{hasSettingsBtn && (
					<div style={{ cursor: 'pointer', paddingRight: '10px' }} className={'grey-svg-image-filter'} onClick={() => settingsClicked()}>
						<img alt="" src={componentsSettingIcon} style={{ width: '24px', height: '24px' }} />
					</div>
				)}
			</div>
			{notes && <div style={{ color: 'var(--app-color-gray-light)', fontSize: '14px', marginTop: '10px', marginBottom: '10px', paddingLeft: '10px' }}>{notes}</div>}
			{error && <MessageContainer message={error} />}
			{loading && show ? (
				<ModalR blured={true} scrollable={true} isOpen={loading && show} shadowedContainerStyle={{ width: '200px' }}>
					<div className={cls.center} style={{ width: '200px', height: '50px' }} onClick={() => setShow(false)}>
						<Spinner type={'BallTriangle'} color="black" height={30} width={`30px`} style={{ marginRight: '30px' }} />
						{'Processing...'}
					</div>
				</ModalR>
			) : null}
		</div>
	);
};

export default FieldRow;
