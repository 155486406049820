import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedProcess, setShowMenu, setShowSideMenu } from '../../reduxStore/actions/flowActions';
import { store } from '../../reduxStore/store';

import cls from './Modules.module.scss';

import * as R from 'ramda';

function ProcessesMenu(props) {
	const navigate = useNavigate();
	const { onSetSelectedProcess, companyProcesses, user, workflowTemplates, companyData } = props;

	return (
		<div className={cls.listContainer} style={{minWidth: '160px'}}>
			{companyData?.status !== 'superadmin' && companyProcesses?.length > 0
				? R.sortWith([R.ascend(R.prop('name'))])(companyProcesses.filter(proc => proc.user_id === user.id || user.is_admin === true))?.map((item, index) => (
					<button
						key={index}
						className={cls.listButton}
						
						onClick={() => {
							store.dispatch(setShowMenu(false));
							store.dispatch(setShowSideMenu(true));
							onSetSelectedProcess({
								name: item?.name,
								description: item?.description,
								id: item?.id,
								is_pinned: item?.is_pinned,
								user_id: item?.user_id,
								registered: true,
								type: item?.type,
								steps: item?.steps,
							});
							navigate('/workflow');
						}}
					>
						<div style={{ fontSize: '16px', fontWeight: '700', minWidth: '200px', textTransform: 'none' }}>
							{item.name}
						</div>
					</button>
				))
				: null}

			{workflowTemplates?.length > 0 &&
				companyData?.status === 'superadmin' &&
				R.sortWith([R.ascend(R.prop('name'))])(workflowTemplates.filter(proc => proc.user_id === user.id || user.is_admin === true))?.map((item, index) => (
					<button
						key={index}
						className={cls.listButton}
						onClick={() => {
							store.dispatch(setShowMenu(false));
							store.dispatch(setShowSideMenu(true));
							onSetSelectedProcess({
								name: item?.name,
								description: item?.description,
								id: item?.id,
								is_pinned: item?.is_pinned,
								user_id: item?.user_id,
								registered: true,
								type: item?.type,
								steps: item?.steps,
							});
							navigate('/workflow');
						}}
					>
						<div style={{ fontSize: '16px', fontWeight: '700' }}>{`${item.name} *`}</div>

					</button>
				))}
			<div>
				<hr />
			</div>

			<button
				className={cls.listButton}
				onClick={() => {
					store.dispatch(setShowMenu(false));
					onSetSelectedProcess({ description: '', id: '', is_pinned: false, name: '', user_id: '', registered: false, type: false });
					navigate('/workflow');
				}}
			>
				<div style={{ fontSize: '16px', fontWeight: '700', textTransform:'none' }}>
					{'Add New Workflow'}
				</div>
			</button>
		</div>
	);
}

const mapStateToProps = store => {
	const { companyPositionsArray, companyProcesses, workflowTemplates, companyData } = store.flowReducer;
	const { user } = store.sessionReducer;
	return { companyPositionsArray, companyProcesses, user, workflowTemplates, companyData };
};
const mapDispatchToProps = dispatch => {
	return { onSetSelectedProcess: process => dispatch(setSelectedProcess(process)) };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProcessesMenu);
