import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { isSafari } from 'react-device-detect';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { setDuplicatePositionInfo, setPositionInfo } from '../../../reduxStore/actions/flowActions';
import { actions_InfoModalOpen, setOnBoardingModalsVisibility, tutorialModalOpen } from '../../../reduxStore/actions/modalsActions';
import {
	sharingUserToPosition,
	removeUserFromPosition,
	addingUserToPosition,
	unsharingUserFromPosition,
	createNewPosition,
	getCompanyDepartmentManagerialPositions,
	getCompanyDepartments,
	updatePosition,
	deletePosition_AndItsChildren,
	deletePosition_AndKeepChildren,
	getCompanyOrgChartPositions,
	addUsersToPosition,
	getAllCompanyPositionsAsArray,
	addPermissionToPosition,
	removePermissionToPosition,
} from '../../../services/company.service';
import { defaultSelectStyles } from '../../../shared/utility';
import { Button, CustomCheckbox, FieldWrapper, ModalR, RenderPositionsPeople, RenderSharedResourcesForPosition, RenderTooltip, RenderYesNoSwitcher } from '../../common';
import cls from './AddNewPositionModal.module.scss';
import { TheRoleInformationContext, useRoleInformationContext } from '../../../context/appRender.context';
import Loader from 'react-loader-spinner';
import { isMobile } from 'react-device-detect';
import { first } from 'rxjs';
import { SingleSelectWithCheckboxesAndNestedItems } from '../../common/UI/Select/MultiSelectWithCheckboxesAndNestedItems';

const AddNewPositionModal = props => {
	const {
		modalVisible,
		onCancel,
		positionInfo,
		onBoardingVisible,
		isOnboarding,
		companyUsersArray,
		companyDepartments,
		onToggleModalVisibility,
		duplicatePositionInfo,
		companyPositionsArray,
		shadowedContainerStyle = { width: isMobile ? '100%' : '40%' },
		onBoardingCompleted,
		onContinueButtonAction,
	} = props;
	const navigate = useNavigate();
	const fieldWrapProps = { useContext: useRoleInformationContext };
	const [positionName, setPositionName] = useState(positionInfo.name);
	const [selectedDepartment, setSelectedDepartment] = useState({ label: companyDepartments.find(d => d.value === positionInfo?.department_id)?.label, value: positionInfo?.department_id });
	const [selectedParentPosition, setSelectedParentPosition] = useState({ label: positionInfo.parent_position?.name, value: positionInfo.parent_position?.id });
	const [parentPositions, setParentPositions] = useState(companyPositionsArray.filter(pos => pos.department === 'Board'));
	const [isManagerialPosition, setIsManagerialPosition] = useState(positionInfo.is_managerial);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showDeleteResult, setShowDeleteResult] = useState({ visible: false, message: '' });
	const [standards, setStandards] = useState(positionInfo?.standards);
	const [tactical, setTactical] = useState(positionInfo?.tactical);
	const [apiErrors, setAPIErrors] = useState({
		positionName: '',
		selectedDepartment: '',
		parentPosition: '',
		isManagerialPosition: '',
		standards: '',
		tactical: '',
	});

	const selNameRef = useRef();
	const selDepartmentRef = useRef();
	const selParentPositionRef = useRef();

	const [elementsTouched, setElementsTouched] = useState({
		positionName: false,
		selectedDepartment: false,
		parentPosition: false,
	});
	const [usersToAddOnPosition, setUsersToAddOnPosition] = useState([]);
	const [usersToShareOnPosition, setUsersToShareOnPosition] = useState([]);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [loading, setLoading] = useState(false);

	const posManParRef = useRef();
	const RenderTitle = props => {
		const { title, tooltipText } = props;
		const paragraphStyle = {
			height: '20px',
			color: 'var(--app-color-gray-light)',
			fontFamily: 'var(--app-text-main-font)',
			fontSize: '16px',
			fontWeight: 500,
			letterSpacing: 0,
			padding: '0px',
			margin: '0px',
		};
		return (
			<div
				style={{
					margin: '16px 0 0 16px ',
					alignSelf: 'stretch',
					flex: 1,
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-start',
					alignItems: 'stretch',
				}}
			>
				<p style={paragraphStyle}>{title}</p>
				<RenderTooltip tooltipText={tooltipText} />
			</div>
		);
	};

	useLayoutEffect(() => {
		retreiveDepartmentsFromServer();
	}, []);
	useEffect(() => {
		return () => {
			clearFlowTransferPositionDatas();
		};
	}, []);

	useEffect(() => {
		if (companyPositionsArray.length === 0) {
			getAllCompanyPositionsAsArray().pipe(first()).subscribe({});
		}
	}, [companyPositionsArray]);
	useEffect(() => {
		setDefaultDuplicationCaseValues();
	}, [companyDepartments]);
	const setDefaultDuplicationCaseValues = useCallback(() => {
		if (companyDepartments?.length > 0 && selectedDepartment?.value?.length == 0 && duplicatePositionInfo?.valid) {
			if (positionName?.length == 0) {
				setPositionName(duplicatePositionInfo?.name);
			}
			const foundDep = companyDepartments.filter(i => i.value == duplicatePositionInfo?.department_id)[0];
			if (foundDep?.value?.length > 0) {
				setSelectedDepartment(foundDep);
				retrieveParentPositions(foundDep?.value);
				setIsManagerialPosition(duplicatePositionInfo?.is_managerial == 1 ? true : false);
			}
		}
	}, [companyDepartments, duplicatePositionInfo, selectedDepartment]);

	const retreiveDepartmentsFromServer = () => {
		getCompanyDepartments().subscribe();
	};
	const retrieveParentPositions = department_id => {
		getCompanyDepartmentManagerialPositions(department_id).subscribe({
			next: data => {
				const parentPos = data?.map(i => ({ label: i.name, value: i.id }));
				setParentPositions([...parentPos]);
			},
			error: _error => {},
		});
	};

	const onUpdatePosition = useCallback(() => {
		const positionToUpdate = {
			name: positionName,
			parent_id: selectedParentPosition?.value,
			department_id: selectedDepartment?.value,
			is_managerial: isManagerialPosition ? '1' : '0',
			standards: standards ? '1' : '0',
			tactical: tactical ? '1' : '0',
		};

		setLoading(true);
		updatePosition(positionInfo.id, positionToUpdate).subscribe({
			next: data => {
				setLoading(false);
				getCompanyOrgChartPositions().pipe(first()).subscribe({});
				getAllCompanyPositionsAsArray().pipe(first()).subscribe({});
				if (!onBoardingCompleted) {
					onContinueButtonAction();
				} else {
					navigate('/chart');
					onCancel();
				}
			},
			error: error => {},
			complete: () => {},
		});
	}, [positionName, selectedDepartment, isManagerialPosition, selectedParentPosition, standards, tactical]);

	const atCreateNewPositions = useCallback(() => {
		setAPIErrors({ positionName: '', selectedDepartment: '', parentPosition: '', isManagerialPosition: '', standards: '' });
		if (!elementsTouched?.positionName) {
			return setAPIErrors(c => ({ ...c, positionName: 'Enter Position name.' }));
		}

		if (selectedParentPosition?.value.length == 0 || selectedParentPosition?.value === undefined) {
			return setAPIErrors(c => ({ ...c, parentPosition: 'Reports To is required' }));
		}

		if (positionName?.length < 1) {
			setAPIErrors(c => ({ ...c, positionName: 'The name field is required.' }));
		}
		if (selectedDepartment?.label?.length < 1) {
			setAPIErrors(c => ({ ...c, selectedDepartment: 'The selected department id is invalid.' }));
		}
		
		const newPosition = {
			name: positionName,
			parent_id: selectedParentPosition?.value,
			department_id: selectedDepartment?.value,
			is_managerial: isManagerialPosition ? '1' : '0',
			standards: standards ? '1' : '0',
			tactical: tactical ? '1' : '0',
		};

		setLoading(true);
		createNewPosition(newPosition).subscribe({
			next: _data => {
				getCompanyOrgChartPositions().pipe(first()).subscribe({});
				getAllCompanyPositionsAsArray().pipe(first()).subscribe({});
				if (!onBoardingCompleted) {
					onContinueButtonAction();
				} else {
					navigate('/chart');
					onCancel();
				}
			},
			error: error => {
				setLoading(false);
				const err = error?.data?.errors;
				if (err) {
					setAPIErrors({
						positionName: err?.name?.length > 0 ? err?.name[0] : '',
						selectedDepartment: err?.department_id?.length > 0 ? err?.department_id[0] : '',
						parentPosition: err?.parent_id?.length > 0 ? err?.parent_id[0] : '',
						isManagerialPosition: err?.is_managerial?.length > 0 ? err?.is_managerial : '',
						standards: err?.standards?.length > 0 ? err?.standards : '',
						tactical: err?.tactical?.length > 0 ? err?.tactical : '',
					});
				}
			},
			complete: () => {
				setLoading(false);
			},
		});
	}, [positionName, selectedDepartment, selectedParentPosition, isManagerialPosition, standards]);
	const clearFlowTransferPositionDatas = () => {
		props.onSetDuplicatePositionInfo({ valid: false, name: '', department_id: '', parent_id: '', is_managerial: 0, standards: 0, tactical: 0 });
	};
	const styles = {
		inputStyle: {
			':focus': { backgroundColor: 'transparent' },
			':active': { backgroundColor: 'transparent' },
			margin: '0px 0 16px 16px ',
			fontFamily: 'var(--app-text-main-font)',
			fontSize: '20px',
			lineHeight: '20px',
			color: '#4A4A4A',
			border: 'none',
			outline: 'none',
			width: '100%',
			padding: '0 0px 0 0px',
		},
	};
	const { inputStyle } = styles;

	const onCloseRoleInformationAction = () => {
		const backTo = location?.state?.backPath;
		if (backTo?.length > 0) {
			navigate(backTo);
		} else {
			onBoardingVisible && isOnboarding ? navigate('/chart') : navigate('/chart');
		}
	};

	const onAddUserOnPosition = useCallback(
		selectedToAdd => {
			console.log('onAddUserOnPosition: ', selectedToAdd, positionInfo?.id?.length > 0, selectedToAdd?.value?.length > 0);
			if (selectedToAdd?.length > 0) {
				selectedToAdd?.forEach(item => AddSingleUserToPosition(item));
			}
		},
		[positionInfo]
	);

	const onAddUsersOnPosition = useCallback(
		selectedToAdd => {
			setLoading(true);
			addUsersToPosition(
				positionInfo?.id,
				selectedToAdd.map(user => user.value)
			).subscribe({
				complete: () => setLoading(false),
			});
		},
		[positionInfo]
	);
	const AddSingleUserToPosition = useCallback(
		singleUser => {
			if (positionInfo?.id?.length > 0 && singleUser?.value?.length > 0) {
				if (positionInfo?.shared_users?.filter(i => i.id == singleUser?.value)?.length > 0) {
					unsharingUserFromPosition(positionInfo?.id, singleUser?.value).subscribe({
						next: data => {
							addingUserToPosition(positionInfo?.id, singleUser?.value).subscribe({
								next: data => {
									console.log('addingUserToPosition, [data]', data);
								},
								error: error => {
									console.log('addingUserToPosition, [error]', error);
								},
								complete: () => {
									console.log('addingUserToPosition delivered sucesfully');
								},
							});
							return;
						},
						error: error => {
							console.log('unsharingUserFromPosition, [error]', error);
						},
					});
				} else {
					addingUserToPosition(positionInfo?.id, singleUser?.value).subscribe({
						next: data => {
							console.log('addingUserToPosition, [data]', data);
						},
						error: error => {
							console.log('addingUserToPosition, [error]', error);
						},
					});
				}
			}
		},
		[positionInfo]
	);

	const onRemoveUserFromPosition = useCallback(
		selectedEmployee => {
			if (positionInfo?.id?.length > 0 && selectedEmployee?.id?.length > 0) {
				removeUserFromPosition(positionInfo?.id, selectedEmployee.id).subscribe({
					next: data => {
						console.log('removeUserFromPosition, [data]', data); // setSelectedEmployee();
					},
					error: error => {
						console.log('removeUserFromPosition, [error]', error);
					},
				});
			}
		},
		[positionInfo]
	);

	const settingUsersToAddOnPosition = val => setUsersToAddOnPosition(val);
	const settingUsersToShareOnPosition = val => setUsersToShareOnPosition(val);
	const onBackButtonPress = () => onCloseRoleInformationAction();
	const deleteThisPosition = () => setShowDeleteModal(true);

	useEffect(() => {
		const usersOnPos = positionInfo?.users?.map(user => user.id);
		const newUsersToAdd = companyUsersArray?.filter(i => usersOnPos.indexOf(i.id) === -1);
		settingUsersToAddOnPosition(newUsersToAdd);
	}, [companyUsersArray, positionInfo]);

	useEffect(() => {
		const usersOnPosIds = positionInfo?.users?.map(it => it.id);
		const sharedUsrIds = positionInfo?.shared_users?.map(it => it.id);
		const newUsersToShare = companyUsersArray?.filter(i => usersOnPosIds.indexOf(i.id) == -1 && sharedUsrIds.indexOf(i.id) == -1);
		settingUsersToShareOnPosition(newUsersToShare);
	}, [companyUsersArray, positionInfo]);

	const onShareUserOnPosition = useCallback(
		selectedToShare => {
			if (positionInfo?.id?.length > 0 && selectedToShare?.value?.length > 0) {
				setLoading(true);
				sharingUserToPosition(positionInfo?.id, selectedToShare?.value).subscribe({
					next: data => {
						console.log('sharingUserToPosition, [data]', data);
					},
					error: error => {
						console.log('sharingUserToPosition, [error]', error);
					},
					complete: () => setLoading(false),
				});
			}
		},
		[positionInfo]
	);

	const onUnshareUserFromPosition = useCallback(
		selectedEmployee => {
			if (positionInfo?.id?.length > 0 && selectedEmployee?.id?.length > 0) {
				setLoading(true);
				unsharingUserFromPosition(positionInfo?.id, selectedEmployee?.id).subscribe({
					next: data => {
						console.log('unsharingUserFromPosition, [data]', data);
					},
					error: error => {
						console.log('unsharingUserFromPosition, [error]', error);
					},
					complete: () => setLoading(false),
				});
			}
		},
		[positionInfo]
	);

	const DeletePositionComponent = props => {
		const { isOpen, onClose, onSuccess } = props;
		const [checkedFirst, setCheckedFirst] = useState(false);
		const [checkedSecond, setCheckedSecond] = useState(false);
		const [showCase, setShowCase] = useState(0);
		const [deleteMessage] = useState('');
		const [, setWarningAnnotation] = useState(false);
		const settingWarningAnnotation = val => {
			setWarningAnnotation(val);
		};
		useEffect(() => {
			if (checkedFirst && checkedSecond) {
				choseFirstOption();
			}
		}, [checkedFirst]);
		useEffect(() => {
			if (checkedSecond && checkedFirst) {
				choseSecondOption();
			}
		}, [checkedSecond]);
		const choseFirstOption = useCallback(() => {
			if (checkedFirst && checkedSecond) {
				setCheckedSecond(false);
			}
		}, [checkedFirst, checkedSecond]);
		const choseSecondOption = useCallback(() => {
			if (checkedSecond & checkedFirst) {
				setCheckedFirst(false);
			}
		}, [checkedFirst, checkedSecond]);
		useEffect(() => {
			if (showCase == 1) {
				var wa = `You are about to delete ${positionInfo.name}`;
				const sps = positionInfo.children.length;
				if (sps > 0) {
					if (checkedFirst) wa += ` and ${sps > 1 ? 'all ' + sps + ' subpositions' : sps + ' subposition '} associated with this position.`;
					if (checkedSecond) wa += `. All ${sps} subpositions associated within will will be assigned to the first higher hierarchical position.`;
				} else {
					wa += '.';
				}
				settingWarningAnnotation(wa);
			}
		}, [showCase]);

		const onBackToPositionPressed = () => {
			setLoading(false);
			setShowCase(0);
			onClose({ succes: undefined, message: 'User canceled.' });
		};

		const onConfirmDeletePressed = useCallback(
			alsoChildren => {
				setDeleteLoading(true);
				if (alsoChildren) {
					deletePosition_AndItsChildren(positionInfo.id).subscribe({
						next: () => {
							setDeleteLoading(false);
							onSuccess();
						},
						error: () => {
							setDeleteLoading(false);

							onClose({ success: false, message: 'Error occured in deleting position.' });
						},
					});
				} else {
					deletePosition_AndKeepChildren(positionInfo.id).subscribe({
						next: () => {
							setDeleteLoading(false);
							onSuccess();
						},
						error: () => {
							setDeleteLoading(false);
							onClose({ success: false, message: 'Error occured in deleting position.' });
						},
					});
				}
			},
			[positionInfo]
		);
		return (
			<ModalR
				shadowedContainerStyle={{ maxWidth: '700px', minWidth: '300px', width: '40%' }}
				onClose={() => {
					onClose({ succes: undefined, message: 'User canceled.' });
				}}
				isOpen={isOpen}
				backdropColor={true}
				blured={true}
			>
				<div className={cls.deleteModalContainer}>
					{showCase == 0 && (
						<div className={cls.deletePositionContainer}>
							<h1 className={cls.deletePosition_title}>{'Delete Position'}</h1>
							<p className={cls.deletePosition_description}>{'Please select the following action:'}</p>
							<div className={cls.deletePosition_checkboxContainer}>
								<CustomCheckbox
									label={'I want to delete the position and all it’s subpositions. '}
									onChange={checked => {
										setCheckedFirst(checked);
									}}
									defaultChecked={checkedFirst}
									mainContainerStyle={{ margin: '0 0 0 0', padding: 0 }}
									checkMarkStyle={{ width: '24px', height: '24px' }}
								/>
							</div>
							<div className={cls.deletePosition_checkboxContainer}>
								<CustomCheckbox
									label={'I want to just delete the position.'}
									onChange={checked => {
										setCheckedSecond(checked);
									}}
									defaultChecked={checkedSecond}
									mainContainerStyle={{ margin: '0 0 0 0', padding: 0 }}
									checkMarkStyle={{ width: '24px', height: '24px' }}
								/>
							</div>
							<div className={cls.deletePosition_buttonContainer} style={{ display: 'flex' }}>
								<Button
									title="Close"
									style={{ marginLeft: '0px', width: '79%', padding: '0 10px' }}
									titleStyle={{ textAlign: 'center' }}
									clicked={() => {
										onClose({ succes: undefined, message: 'User canceled.' });
									}}
								/>
								<Button title="Delete" style={{ marginLeft: '0px', width: '79%', padding: '0 10px' }} light loading={deleteLoading} titleStyle={{ textAlign: 'center' }} clicked={() => onConfirmDeletePressed(checkedFirst && !checkedSecond)} />
							</div>
						</div>
					)}

					{showCase == 2 && (
						<div className={cls.loadingContainer}>
							<Loader type={isSafari ? 'Watch' : 'Grid'} color="var(--app-color-gray-dark)" /*  visible={props.loading} */ secondaryColor="blue" height={'250px'} width={'250px'} /*radius={50} height={100} */ />
						</div>
					)}
					{showCase == 3 && (
						<div className={cls.resultOperationWrapper}>
							<div className={cls.resultOperationContainer}>
								<div className={cls.resultContainer}>
									<h1 className={cls.resultText}>{deleteMessage}</h1>
								</div>
								<div className={cls.buttonContainer}>
									<Button title="Back to Chart" style={{ marginLeft: '0px' /* width: '80%' */ }} titleStyle={{ textAlign: 'center', padding: '0 10px 0 0' }} clicked={() => onBackToPositionPressed()} />
								</div>
							</div>
						</div>
					)}
				</div>
			</ModalR>
		);
	};

	const DeletePositionResult = props => {
		const { isOpen, onClose, message } = props;
		const [deleteMessage] = useState(message);
		return (
			<ModalR backdropStyle={{ height: '100%' }} shadowedContainerStyle={{ maxWidth: '700px', minWidth: '475px' }} marginTop={'2%'} onClose={onClose} hasCloseButton position={'fixed'} scrollable={true} backdropColor={true} blured={true} width={'60%'} isOpen={isOpen}>
				<div className={cls.duplicateModalContainer}>
					<div className={cls.resultOperationWrapper}>
						<div className={cls.resultOperationContainer}>
							<div className={cls.resultContainer}>
								<h1 className={cls.resultText}>{deleteMessage}</h1>
							</div>
							<div className={cls.buttonContainer}>
								<Button title="Back to Chart" style={{ marginLeft: '0px' /* width: '80%' */ }} titleStyle={{ textAlign: 'center', padding: '0 10px 0 0' }} clicked={() => onClose()} />
							</div>
						</div>
					</div>
				</div>
			</ModalR>
		);
	};
	return (
		<TheRoleInformationContext>
			<ModalR blured={true} scrollable={true} isOpen={modalVisible} onClose={onCancel} shadowedContainerStyle={{ width: isMobile ? '100%' : '30%' }}>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
					<div
						style={{
							margin: 'auto',
							marginTop: '30px',
							display: 'flex',
							flexDirection: 'row',
							width: '100%',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<h1
							style={{
								color: '#4A4A4A',
								fontFamily: 'var(--app-text-main-font)',
								fontSize: '2em',
								lineHeight: '2em',
								margin: 'auto',
								padding: '0 0 0 10px',
								//textAlign: props.headingCentered ? 'center' : 'left',
							}}
						>
							{positionInfo.name !== '' ? 'Edit Role/Position' : 'Add Role/Position'}
						</h1>
					</div>
				</div>
				<div className={cls.target_XI} style={{ padding: '0 10px 0 10px' }}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'flex-start',
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								flex: 1,
							}}
						>
							<div
								style={{
									margin: '16px 0 8px  0',
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
									alignItems: 'flex-start',
									border: '1px solid var(--app-color-gray-lighter)',
									borderRadius: '4px',
								}}
							>
								<div
									style={{
										display: 'flex',
										flex: 1,
										flexDirection: 'column',
										justifyContent: 'space-between',
										alignItems: 'center',
									}}
								>
									<RenderTitle title={'Position Name'} />
									<div
										style={{
											margin: '20px 0px 0 0',
											display: 'flex',
											flex: 1,
											flexDirection: 'row',
											justifyContent: 'space-evenly',
											alignItems: 'stretch',
											width: '100%',
										}}
									>
										<input
											type="text"
											ref={selNameRef}
											autoComplete="off"
											name="positionName"
											style={inputStyle}
											placeholder=""
											value={positionName}
											onChange={e => {
												setPositionName(e.target.value);
											}}
											onFocus={() => {
												setElementsTouched(c => ({ ...c, positionName: true }));
												setAPIErrors(c => ({ ...c, positionName: '' }));
											}}
										/>
									</div>
								</div>
							</div>
							{apiErrors?.positionName?.length > 0 && (
								<div>
									<div
										style={{
											padding: '0 20px 0 20px',
											color: '#721c24',
											backgroundColor: '#f8d7da',
											borderColor: '#f5c6cb',
										}}
									>
										{apiErrors?.positionName}
									</div>
								</div>
							)}
							<div
								style={{
									margin: '8px 0 8px  0',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-between',
									alignItems: 'stretch',
									border: '1px solid var(--app-color-gray-lighter)',
									borderRadius: '4px',
								}}
							>
								<div
									style={{
										display: 'flex',
										flex: 1,
										flexDirection: 'row',
										justifyContent: 'space-between',
										alignItems: 'stretch',
									}}
								>
									<RenderTitle title={'Department'} />
								</div>
								<div
									style={{
										margin: '20px 0px 0 0',
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-evenly',
										alignItems: 'stretch',
										width: '100%',
									}}
								>
									<Select
										ref={selDepartmentRef}
										openMenuOnFocus={true}
										{...props}
										isSearchable
										key={'Position'}
										styles={defaultSelectStyles}
										defaultInputValue={selectedDepartment?.label ?? ''}
										value={
											companyDepartments?.filter(({ value }) => {
												return value === selectedDepartment.value;
											}) ?? { value: 'No Department', id: 'No Department' }
										}
										placeholder=""
										options={[{ id: 'None', label: 'No Department' }, ...companyDepartments]}
										onChange={dep => {
											if (dep?.value?.length > 0) {
												setSelectedDepartment(dep);
											} else {
												setSelectedDepartment({ value: '', id: '' });
											}
										}}
										onFocus={() => {
											setElementsTouched(c => ({ ...c, selectedDepartment: true }));
											setAPIErrors(c => ({ ...c, selectedDepartment: '' }));
										}}
									/>
								</div>
							</div>
							{apiErrors?.selectedDepartment?.length > 0 && (
								<div>
									<div
										style={{
											padding: '0 20px 0 20px',
											color: '#721c24',
											backgroundColor: '#f8d7da',
											borderColor: '#f5c6cb',
										}}
									>
										{apiErrors?.selectedDepartment}
									</div>
								</div>
							)}
							<div
								style={{
									margin: '8px 0 8px  0',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-between',
									alignItems: 'stretch',
									border: '1px solid var(--app-color-gray-lighter)',
									borderRadius: '4px',
								}}
							>
								<div
									style={{
										display: 'flex',
										flex: 1,
										flexDirection: 'row',
										justifyContent: 'space-between',
										alignItems: 'stretch',
									}}
								>
									<RenderTitle title={'Reports To '} />
								</div>
								<div
									style={{
										margin: '10px 0px 0 0',
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-evenly',
										alignItems: 'stretch',
										width: '100%',
									}}
								>
									<SingleSelectWithCheckboxesAndNestedItems
										placeholder={``}
										items={companyPositionsArray}
										groupedBy={'department'}
										groupname={'department'}
										triggerType={'chevron'}
										inputStyle={{ cursor: 'pointer' }}
										initialSelectedValues={companyPositionsArray.filter(pos => pos.label === selectedParentPosition?.label)}
										className={cls.peoplePositionsSelect}
										inputClassName={cls.peoplePositionsSelectInput}
										molclassname={cls.peoplePositionsSelectMenuOverlay}
										moliclassname={cls.peoplePositionsSelectMenuOverlayItem}
										onChange={dep => {
											if (dep?.length > 0) {
												setSelectedParentPosition(dep[0]);
											} else {
												setSelectedParentPosition({ value: '', id: '' });
											}
										}}
									/>
								</div>
							</div>
							{apiErrors?.parentPosition?.length > 0 && (
								<div>
									<div
										style={{
											padding: '0 20px 0 20px',
											color: '#721c24',
											backgroundColor: '#f8d7da',
											borderColor: '#f5c6cb',
										}}
									>
										{apiErrors?.parentPosition}
									</div>
								</div>
							)}

							<div style={{ padding: '0 20px 0 0px' }}>
								<RenderYesNoSwitcher
									{...{
										id: 'Is this a Managerial Position?',
										title: 'Is this a Managerial Position?',
										ungrouped: true,
										withAckButton: false,
									}}
									style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
									value={isManagerialPosition ? true : false}
									toggleSelected={val => {
										setIsManagerialPosition(val);
									}}
								/>
							</div>

							{apiErrors?.isManagerialPosition?.length > 0 && (
								<div>
									<div
										style={{
											padding: '0 20px 0 20px',
											color: '#721c24',
											backgroundColor: '#f8d7da',
											borderColor: '#f5c6cb',
										}}
									>
										{apiErrors?.isManagerialPosition}
									</div>
								</div>
							)}
							<div style={{ padding: '0 20px 0 0px', display: positionInfo.name !== '' ? '' : 'none' }}>
								<RenderYesNoSwitcher
									{...{
										id: 'Allow creation of tasks?',
										title: 'Allow creation of tasks?',
										ungrouped: true,
										withAckButton: false,
									}}
									style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
									value={positionInfo?.permissions?.find(per => per.name === 'view-dashboard-task' && per.is_ui === 1) ? true : false}
									toggleSelected={val => {
										if (val == true) {
											addPermissionToPosition(positionInfo.id, 'view-dashboard-task').subscribe({});
										} else {
											removePermissionToPosition(positionInfo.id, 'view-dashboard-task').subscribe({});
										}
									}}
								/>
							</div>
							<div style={{ padding: '0 20px 0 0px', fontSize: '14px', color: 'var(--app-color-gray-light)' }}>
								<RenderYesNoSwitcher
									{...{
										id: 'Single module user?',
										title: 'Single module user?',
										ungrouped: true,
										withAckButton: false,
									}}
									style={{ margin: '20px 0px 20px 0px', width: 'auto' }}
									value={standards ? true : false}
									toggleSelected={val => {
										setStandards(val);
									}}
								/>
								{standards && 'Important: Make sure that the position name is similar to the module being set as single app'}
							</div>

							{apiErrors?.standards?.length > 0 && (
								<div>
									<div
										style={{
											padding: '0 20px 0 20px',
											color: '#721c24',
											backgroundColor: '#f8d7da',
											borderColor: '#f5c6cb',
										}}
									>
										{apiErrors?.standards}
									</div>
								</div>
							)}

							<br />
							<div id={'listsArea'} className={cls.target_XI} style={{ display: positionInfo.name !== '' ? '' : 'none' }}>
								<FieldWrapper id={'peopleInThisPosition'} title={'Users assigned to this position:'} value={''} otherContent {...fieldWrapProps}>
									<RenderPositionsPeople
										{...fieldWrapProps}
										id={'peopleInThisPosition'}
										onAddUserOnPosition={onAddUserOnPosition}
										onAddUsersOnPosition={onAddUsersOnPosition}
										onRemoveUserFromPosition={onRemoveUserFromPosition}
										usersOnPosition={positionInfo?.users}
										usersToAddOnPosition={companyUsersArray}
										loading={loading}
									/>
								</FieldWrapper>
								<br />
								<FieldWrapper id={'sharedresources'} title={'Shared Resources:'} value={''} otherContent {...fieldWrapProps}>
									<RenderSharedResourcesForPosition
										{...fieldWrapProps}
										id={'sharedresources'}
										sharedUsersOnPosition={positionInfo?.shared_users}
										usersToShareOnPosition={usersToShareOnPosition}
										onShareUserOnPosition={onShareUserOnPosition}
										onUnshareUserFromPosition={onUnshareUserFromPosition}
										loading={loading}
									/>
								</FieldWrapper>
							</div>

							<div
								style={{
									alignSelf: 'stretch',
									display: 'flex',
									flexDirection: 'row',
									width: '100%',
									justifyContent: 'center',
									alignItems: 'center',
									margin: '20px 0 0 0',
								}}
							>
								{onBoardingCompleted && (
									<Button
										disabled={props.continueDisabled}
										loading={props.loading ? props.loading : undefined}
										clicked={_e => {
											onToggleModalVisibility({});
										}}
										style={{}}
										title={'Close'}
									/>
								)}
								{!onBoardingCompleted && (
									<Button
										disabled={props.continueDisabled}
										loading={props.loading ? props.loading : undefined}
										clicked={_e => {
											//onContinueButtonAction();
											onToggleModalVisibility({ fourthModalVisible: true });
										}}
										style={{}}
										title={'Close'}
									/>
								)}
								{positionInfo.name === '' && (
									<Button
										light
										disabled={props.continueDisabled}
										loading={loading}
										clicked={_e => {
											atCreateNewPositions();
										}}
										style={{}}
										title={'Save'}
									/>
								)}
								{positionInfo.name !== '' && (
									<>
										<Button light disabled={props.continueDisabled} loading={loading} clicked={_e => onUpdatePosition()} style={{}} title={'Update'} />
										<Button light disabled={positionInfo?.shared_users.length + positionInfo?.users.length > 0} clicked={deleteThisPosition} style={{}} title={'Delete'} />
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</ModalR>
			{showDeleteModal && (
				<DeletePositionComponent
					isOpen={showDeleteModal}
					onClose={p => {
						setShowDeleteModal(false);
						if ([true, false].indexOf(p?.success) > -1) {
							setShowDeleteResult({
								visible: true,
								message: p.message,
							});
						}
					}}
					onSuccess={() => {
						navigate('/chart');
						setShowDeleteModal(false);
						onCancel();
					}}
				/>
			)}
			{showDeleteResult?.visible && (
				<DeletePositionResult
					isOpen={showDeleteResult?.visible}
					message={showDeleteResult?.message}
					onClose={() => {
						setShowDeleteResult({ visible: false, message: '' });
						onBackButtonPress();
					}}
				/>
			)}
		</TheRoleInformationContext>
	);
};

const mapStateToProps = store => {
	const { positionInfo, companyDepartments, duplicatePositionInfo, companyUsersArray, companyPositionsArray, onBoardingCompleted } = store.flowReducer;

	return { positionInfo, companyDepartments, duplicatePositionInfo, companyUsersArray, companyPositionsArray, onBoardingCompleted };
};
const mapDispatchToProps = dispatch => {
	return {
		onToggleModalVisibility: modalVisible => dispatch(setOnBoardingModalsVisibility(modalVisible)),

		onActions_InfoModalOpen: options => dispatch(actions_InfoModalOpen({ isOpen: true, options: options })),
		onSetPositionInfo: field => dispatch(setPositionInfo(field)),
		onSetDuplicatePositionInfo: dPosInfo => dispatch(setDuplicatePositionInfo(dPosInfo)),
		onTutorialModalOpen: options => dispatch(tutorialModalOpen(options)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(AddNewPositionModal);
