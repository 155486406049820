import classNames from 'classnames';
import React, { ChangeEventHandler, ComponentProps, forwardRef, ReactNode, useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import cls from './TextField.module.scss';

export type TextFieldProps = {
	/** Label of the input field */
	label?: string;
	/** Check if the field is mandatory or not */
	hasMandatoryIndicator?: boolean;
	/** The text shown within the input field, in the default state */
	placeholder?: string;
	/** Number of characters allowed */
	charactersNo?: number;
	/** The icon type used for the input field*/
	icon?: ReactNode;
	/**  Right button for features as show/hide  */
	rightButtonLabel?: string;
	rightButtonAction?: () => void;
	rightButtonClass?: string;
	/** Error message */
	errorMessage?: string;
	/** It is shown below the input field or
	 * below the error message (if applicable ) */
	helpText?: string;
	type?: ComponentProps<'input'>['type'];
	isDisabled?: ComponentProps<'input'>['disabled'];
	readOnly?: ComponentProps<'input'>['readOnly'];
	defaultValue?: string;
	autoComplete?: string;
} & ComponentProps<'div'> &
	Partial<UseFormRegisterReturn>;

/**
 * Text fields allow users to enter text into a UI.
 * They typically appear in create processes or dialogues.
 */
const TextField = forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => {
	const { isDisabled = false, readOnly, label = '', hasMandatoryIndicator, placeholder = 'Insert email', charactersNo, icon, rightButtonLabel, rightButtonAction, rightButtonClass, errorMessage, defaultValue, onChange, name, type = 'text', autoComplete, ...rest } = props;
	const [inputValue, setInputValue] = useState(defaultValue ?? '');
	const hasError = Boolean(errorMessage);

	const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
		setInputValue(event.target.value);
		onChange?.(event);
	};

	return (
		<div {...rest}>
			<Label hasMandatoryIndicator={hasMandatoryIndicator}>{label}</Label>
			<InputContainer hasError={hasError} className={cls.inputContainer}>
				<Input
					app-readonly={readOnly ? 'readonly' : ''}
					readOnly={readOnly}
					autoComplete={autoComplete}
					disabled={isDisabled}
					name={name}
					type={type}
					placeholder={placeholder}
					value={inputValue}
					maxLength={charactersNo}
					onChange={handleChange}
					ref={ref}
					className={cls.textInput}
				/>
				{icon && <div className={cls.ornament}>{icon}</div>}
				{rightButtonLabel && (
					<button type={'button'} onClick={rightButtonAction} className={classNames(cls.rightButton, rightButtonClass)}>
						{rightButtonLabel}
					</button>
				)}
			</InputContainer>

			{errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
		</div>
	);
});

export const UserTextField = forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => {
	const { isDisabled = false, readOnly, label = '', hasMandatoryIndicator, placeholder = 'Insert email', charactersNo, icon, rightButtonLabel, rightButtonAction, rightButtonClass, errorMessage, defaultValue, onChange, name, type = 'text', autoComplete, ...rest } = props;
	const [inputValue, setInputValue] = useState(defaultValue ?? '');
	const hasError = Boolean(errorMessage);

	const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
		setInputValue(event.target.value);
		onChange?.(event);
	};

	return (
		<div {...rest}>
			<Label hasMandatoryIndicator={hasMandatoryIndicator}>{label}</Label>
			<InputContainer hasError={hasError} className={cls.inputContainer}>
				<Input
					app-readonly={readOnly ? 'readonly' : ''}
					readOnly={readOnly}
					autoComplete={autoComplete}
					disabled={isDisabled}
					name={name}
					type={type}
					placeholder={placeholder}
					value={inputValue}
					maxLength={charactersNo}
					onChange={handleChange}
					ref={ref}
					className={cls.userTextInput}
				/>
				{icon && <div className={cls.ornament}>{icon}</div>}
				{rightButtonLabel && (
					<button type={'button'} onClick={rightButtonAction} className={classNames(cls.rightButton, rightButtonClass)} style={{fontFamily: 'Montserrat', fontSize:'14px', fontWeight:'normal'}}>
						{rightButtonLabel}
					</button>
				)}
			</InputContainer>

			{errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
		</div>
	);
});
export const InputContainer = forwardRef<HTMLDivElement, { hasError: boolean } & ComponentProps<'div'>>((props, ref) => {
	const { className, hasError, ...rest } = props;
	return <div app-error={hasError ? 'error' : ''} ref={ref} {...rest} className={classNames(cls.inputContainer, className)} />;
});

/**
 * Input contol with applied styling to be used **as inside of `InputContainer`**
 * Uses less bottom margin when active to balance the stronger `border` of the `InputContiner`
 */
export const Input = forwardRef<HTMLInputElement, ComponentProps<'input'>>((props, ref) => {
	const { className, ...rest } = props;
	return <input ref={ref} {...rest} className={classNames(cls.textInput, className)} />;
});
export function ErrorMessage(props: ComponentProps<'p'>) {
	const { children, ...rest } = props;
	return (
		<p className={cls.errorContainer} {...rest}>
			{children}
		</p>
	);
}
type LabelProps = {
	/** adds a "*"-Indicator to the end of the title that turns red in case of an error */
	hasMandatoryIndicator?: boolean;
} & ComponentProps<'label'>;

/**
 * displayed above an `input` control.
 */
export function Label(props: LabelProps) {
	const { hasMandatoryIndicator, children, className, ...rest } = props;
	return (
		<label className={classNames(cls.inputLabel, className)} {...rest}>
			{children}
			{hasMandatoryIndicator && <span className="text-danger">*</span>}
		</label>
	);
}
export default TextField;
