import React from 'react';
import classNames from 'classnames';
import { UseMultipleSelectionGetSelectedItemPropsOptions } from 'downshift';
import { ComponentProps, forwardRef } from 'react';
import { Add, Checked, Chevron, CloseXCircle, DropDownArrow, ellipse, Eyeglass, radioButton, Step, VectorDown, XSign } from '../../../../assets/images/icons';
import cls from './SelectComponents.module.scss';
import { FaFontAwesome } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import zIndex from '@material-ui/core/styles/zIndex';

export type Item = { value: string; label: string; icon?: string; fields?: object };

export type LabelProps = { hasMandatoryIndicator?: boolean } & ComponentProps<'label'>;
export const Label = (props: LabelProps) => {
	const { hasMandatoryIndicator, children, className, style, ...rest } = props;
	return (
		<label className={classNames(cls.customLabel, className)} style={style} {...rest}>
			{children}
			{hasMandatoryIndicator && <span style={{ color: 'var(--app-color-error)' }}>*</span>}
		</label>
	);
};
export type RenderTagsProps = {
	selectedItems: Item[];
	getSelectedItemProps?: (options: UseMultipleSelectionGetSelectedItemPropsOptions<Item>) => any;
	removeSelectedItem?: (item: Item) => void;
	hasCloseButton: TagProps['hasCloseButton'];
	tagType?: TagProps['tagType'];
	labelsTag?: boolean;
	closeButtonType?: TagProps['closeButtonType'];
} & ComponentProps<'div'>;
export const RenderTags = (props: RenderTagsProps) => {
	const { selectedItems, getSelectedItemProps, removeSelectedItem, className, hasCloseButton, closeButtonType, labelsTag, tagType, ...rest } = props;

	return (
		<div className={classNames(cls.tagsContainer, className)} {...rest}>
			{selectedItems.map((selectedItem, index) => (
				<Tag
					hasCloseButton={hasCloseButton}
					key={`selected-item-${index}`}
					{...getSelectedItemProps?.({ selectedItem, index })}
					onClick={e => {
						e.stopPropagation();
						removeSelectedItem?.(selectedItem);
					}}
					tagType={tagType}
					closeButtonType={closeButtonType}
				>
					{selectedItem?.icon && <img alt="item-icon" className={cls.itemLeftIcon} src={selectedItem?.icon} />}
					{labelsTag ? selectedItem.label : selectedItem.value}
				</Tag>
			))}
		</div>
	);
};
export type TagProps = {
	hasCloseButton?: boolean;
	closeButtonType?: 'circleX' | 'X' | '';
	tagType?: 'shadowed' | 'step-plated' | 'plated' | '';
} & ComponentProps<'div'>;
export const Tag = forwardRef<HTMLDivElement, TagProps>((props, ref) => {
	const { children, className, hasCloseButton, closeButtonType, tagType, ...rest } = props;
	return (
		<div
			ref={ref}
			tabIndex={hasCloseButton ? 0 : undefined}
			app-variant={hasCloseButton ? 'withCloseIco' : 'without-close-icon'}
			className={classNames(cls.hostTagDefault, { [cls.tagShadowed]: tagType == 'shadowed' }, { [cls.tagPlated]: ['step-plated', 'plated'].indexOf(tagType ?? '') > -1 }, className)}
			{...rest}
		>
			{typeof children == 'string' ? <p className={cls.tagParagraph}>{children}</p> : children}	
			{hasCloseButton &&  <FontAwesomeIcon icon={faClose} className={cls.tagXCloseIcon} />}
		</div>
	);
});
export const ErrorMessage = (props: ComponentProps<'p'>) => {
	return <p className={cls.customErrorMessage} {...props} />;
};
export type ToggleMenuButtonProps = {
	isOpen: boolean;
	triggerType?: 'chevron' | 'downArrow' | 'eyeglass';
	extraControl: JSX.Element;
} & ComponentProps<'button'>;
export const ToggleMenuButton = forwardRef<HTMLButtonElement, ToggleMenuButtonProps>((props, ref) => {
	const { isOpen, children, className, extraControl, triggerType, ...rest } = props;

	return (
		<button ref={ref} {...rest} type="button" className={classNames(cls.toggleMenuButton, className)} aria-label="toggle menu">
			{children}
			<div style={{ display: 'flex', alignItems: 'center' }}>
				{extraControl}
				{triggerType !== 'eyeglass' && (
					<VectorDown
						style={{
							margin: '0 10px 0 8px',
							transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
							transition: 'transform 0.6s ease',
						}}
					/>
				)}
				{triggerType === 'eyeglass' && <Eyeglass className={cls.toggleMenuInputButtonIcon} />}
			</div>
		</button>
	);
});

export const SelectTextBox = forwardRef<HTMLButtonElement, ToggleMenuButtonProps>((props,ref) => {
	
	const { children, className } = props;
	return(
		<div  ref={ref} className={classNames(cls.toggleMenuButton, className)} aria-label="toggle menu">
			{children}
			<div style={{ display: 'flex', alignItems: 'center' }}>
				{/* {extraControl} */}
			</div>
		</div>
	);
});

export type ToggleMenuInputButtonProps = {
	isOpen: boolean;
	triggerType?: 'chevron' | 'downArrow' | 'eyeglass';
} & ComponentProps<'button'>;
export const ToggleMenuInputButton = forwardRef<HTMLButtonElement, ToggleMenuInputButtonProps>((props, ref) => {
	const { children, isOpen, className, triggerType, ...rest } = props;
	return (
		<>
			{triggerType ? (
				<button ref={ref} type="button" aria-label="toggle-menu" className={classNames(cls.toggleMenuInputButton, className)} app-variant={isOpen ? 'open' : 'closed'} app-icon={triggerType ? triggerType : ''} {...rest}>
					{children}
					{triggerType === 'chevron' && <Chevron className={cls.toggleMenuInputButtonIcon} />}
					{triggerType === 'downArrow' && <DropDownArrow className={cls.toggleMenuInputButtonIcon} />}
					{triggerType === 'eyeglass' && <Eyeglass className={cls.toggleMenuInputButtonIcon} />}
				</button>
			) : 
				children
			}
		</>
	);
});
export const InputContainer = forwardRef<HTMLDivElement, { hasResponsiveHeight: boolean; isActive: boolean; hasError: boolean } & ComponentProps<'div'>>((props, ref) => {
	const { hasResponsiveHeight, isActive, hasError, className, style, ...rest } = props;
	return <div ref={ref} {...rest} className={classNames(cls.inputContainer, className)} style={{ ...style }} app-state={isActive ? 'active' : hasError ? 'error' : ''} app-variant={hasResponsiveHeight ? 'responsive' : 'fixed'} />;
});
export const Input = forwardRef<HTMLInputElement, ComponentProps<'input'>>((props, ref) => {
	const { className, ...rest } = props;
	return <input ref={ref} className={classNames(cls.input, className)} {...rest} />;
});
export const MenuOverlayList = forwardRef<HTMLUListElement, ComponentProps<'ul'>>((props, ref) => {
	const { className, style, ...rest } = props;
	return <ul ref={ref} {...rest} className={classNames(cls.menuOverlayList, className)} style={{ ...style }} />;
});
export type MenuOverlayListItemProps = {
	isVisible: boolean;
	isHighlighted?: boolean;
	isSelected?: boolean;
	isUnique: boolean;
	isReadOnly: boolean;
	itemType?: 'checkboxes' | 'normal' | 'radio' | 'no-item-icons';
	checkboxEmplacement?: 'left' | 'right';
	icon: string;
	simple?: boolean;
	checkboxes?: boolean;
	selectType?: 'radio' | '';
	leftIconItemContainerClassName?: string;
} & ComponentProps<'li'>;
export const MenuOverlayListItem = forwardRef<HTMLLIElement, MenuOverlayListItemProps>((props, ref) => {
	const { checkboxEmplacement, itemType, icon, children, className, isSelected, isHighlighted, isUnique, isReadOnly, isVisible, style, leftIconItemContainerClassName, ...rest } = props;
	if (!isVisible) return null;

	return (
		<li
			ref={ref}
			className={classNames(cls.menuOverlayListItem, className)}
			app-variant-selected={isSelected ? 'selected' : ''}
			app-variant-highlighted={isHighlighted ? 'highlighted' : ''}
			app-item-type={itemType ? itemType : ''}
			app-item-display={itemType ? itemType : ''}
			app-item-separated={isUnique ? 'unique' : ''}
			app-item-read-only={isReadOnly ? 'readonly' : ''}
			style={style}
			{...rest}
		>
			{itemType === 'checkboxes' && checkboxEmplacement === 'left' && (
				<span>
					<div className={classNames(cls.menuOverlayListItemCheckbox)}>{isSelected && <Checked className={cls.menuOverlayListItemCheckboxCheckedMark} />}</div>
				</span>
			)}
			{icon && (
				<span className={classNames(cls.leftIconItemContainer, leftIconItemContainerClassName)}>
					<img className={cls.leftIconItem} src={icon} alt="ico" />
				</span>
			)}
			<span className={classNames(cls.menuOverlayListItemChild, { className: itemType === 'no-item-icons' })}>{children}</span>
			{itemType === 'normal' && <span>{isSelected ? <Checked className={cls.menuOverlayListItemNormalCheckedMark} /> : !isReadOnly ? <Add className={cls.menuOverlayListItemNormalCheckedMark} /> : null}</span>}
			{itemType === 'checkboxes' && checkboxEmplacement === 'right' && <div className={classNames(cls.menuOverlayListItemCheckbox)}>{isSelected && <Checked className={cls.menuOverlayListItemCheckboxCheckedMark} />}</div>}
			{itemType === 'radio' && <img alt={''} src={isSelected ? radioButton : ellipse} />}
		</li>
	);
});
