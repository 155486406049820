import cls from './CustomTemplateModuleList.module.scss';
import { useTable } from 'react-table';
import { isMobile } from 'react-device-detect';
import { useState } from 'react';

export const CustomTemplateModuleList = props => {
	const { columns = [], layeredColumns = [], isReport, title, list = [], footer = [], onListItemClick = () => { },onCellClick= () => {},  className, children, tableStyle, tableHostSimple, hideHeaders = false } = props;
	const [selected, setSelected] = useState(undefined);
	const columnsF = isMobile ? columns.slice(0, 5) : columns;

	function Table({ columns, data }) {
		const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
			columns,
			data,
		});
		return (
			<div className={title ? (isReport ? cls.tableReport : tableHostSimple ? tableHostSimple : cls.tableHost) : tableHostSimple ? tableHostSimple : cls.tableHostSimple} style={{ ...tableStyle, fontSize: isMobile ? '12px' : '16px' }}>
				<table {...getTableProps()} size="sm">
					<thead style={{ display: hideHeaders ? 'none' : '', }}>
						{headerGroups.map((headerGroup, index) => (
							<tr key={index} {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column, idx) => (
									<th key={idx} {...column.getHeaderProps()}>
										<div style={{ border: '0' }}>{column.render('Header')}</div>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{rows.map(
							(row, index) =>
								prepareRow(row) || (
									<tr
										key={index}
										{...row.getRowProps()}
										onClick={() => {
											setSelected(index);
											onListItemClick(list[row.index]);
										}}
										style={{pointerEvents: list[index]?.enabled ? '' : list[index]?.enabled == false ? 'none' : '' }}
									>
										{row.cells.map((cell, idx) => {
											return (
												<td key={idx} {...cell.getCellProps()}
												onClick={e=>{
													onCellClick({cell: cell.render('Cell'), column: idx, row: index, data:list[row.index], top: e.clientX, left:e.clientY });
												}}
												>
													<div style={{ border: '0' }}>{cell.render('Cell')}</div>
												</td>
											);
										})}
									</tr>
								)
						)}
						{footer.length > 0 && (
							<tr>
								{(isMobile ? footer.slice(0, 3) : footer).map((f, i) => {
									return (
										<td key={i}>
											<div className={cls.footer}>{f}</div>
										</td>
									);
								})}
							</tr>
						)}
					</tbody>
				</table>
			</div>
		);
	}

	return (
		<div className={className ?? ''} app-variant-is-mobile={isMobile ? 'true' : 'false'} style={{ ...props.style, display: 'flex', flexDirection: 'column', margin: 'auto' }}>
			<div style={{ marginBottom: '10px', width: '100%' }}>
				{children}
				{!title && columnsF.length > 0 && (
					<Table
						columns={columnsF.map(x => {
							return {
								Header: () => {
									return <span>{x}</span>;
								},
								accessor: x,
							};
						})}
						data={list}
					/>
				)}

				{title && columnsF.length > 0 && (
					<Table
						columns={[
							{
								Header: title,
								columns: columnsF.map(col => {
									return {
										Header: col,
										accessor: col,
									};
								}),
							},
						]}
						data={list}
					/>
				)}
				{layeredColumns.length > 0 &&
					<Table

						columns={layeredColumns.map(col => {
							if (col?.subColumns){
								return {
									Header: col.label,
									columns: col.subColumns.map(col => {
										return {
											Header: col.label,
											accessor: col.label,
										};
									}),
								};
							}
							
							return {
								Header: () => {
									return <span>{col.label}</span>;
								},
								accessor: col.label,
							};


						})}
						data={list}
					/>


				}

			</div>
		</div>
	);
};

export default CustomTemplateModuleList;
