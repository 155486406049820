import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import MenuBar from '../../components/TopBar/Navigation/NavigationItems/MenuBar';
import MessageBar from '../../components/TopBar/MessageBar';
import { setLastKnownRoute } from '../../reduxStore/actions/authActions';
import { userLogout } from '../../services/auth.service';
import cls from './Layout.module.scss';
import { isMobile } from 'react-device-detect';

const Layout = props => {
	const location = useLocation();
	const dispatch = useDispatch();
	const { authenticated } = useSelector(store => store.sessionReducer);

	useEffect(() => {
		dispatch(setLastKnownRoute(location.pathname));
	}, [location.pathname, setLastKnownRoute]);

	return (
		<div className={cls.host}>
			<main style={{ width: '100%' }}>
				{props.children}
				{/* {!authenticated && ( */}
					<div>
						<p className={cls.copyright}>
							{'ADPGxSOFTWARE SOLUTIONS © 2024 | ALL RIGHTS RESERVED | '}
							&ensp;
							<a href={'https://adpgxsoftware.com/#/legal'}>{'PRIVACY POLICY'}</a>
						</p>
					</div>
				{/* )} */}
			</main>
		</div>
	);
};

export const Divider = props => <div style={{ display: 'flex', width: 'inherit' }}>{props.children}</div>;

export const Top = () => {
	const { onBoardingCompleted, showMessage, showMenu } = useSelector(store => store.flowReducer);
	const { authenticated } = useSelector(store => store.sessionReducer);

	const navigate = useNavigate();

	const userLogoutHandler = () => {
		userLogout().subscribe({
			next: _data => navigate('/login'),
			error: _error => navigate('/'),
		});
	};
	return (
		<div>
			{authenticated && showMessage?.show === true ? <MessageBar /> : authenticated && showMenu && <MenuBar userLogoutHandler={userLogoutHandler} isAuthenticated={authenticated && onBoardingCompleted} />}
		</div>
	);
};
export const Left = props => {
	const { showMenu, allModules, showSideMenu } = useSelector(store => store.flowReducer);

	return (
		<div
			style={{
				backgroundColor: 'rgb(246,244,236)',
				width: isMobile ? '0' : '12%',
				padding: '100px 10px 20px 10px',
				minWidth: '160px',
				display: !isMobile && showMenu && showSideMenu && allModules.length > 1 ? '' : 'none',
			}}
		>
			{props.children}
		</div>
	);
};

export const Right = props => {
	return <div style={{ width: '100%', minHeight: '90vh' }}>{props.children}</div>;
};

export default Layout;
